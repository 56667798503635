body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Helvetica Neue", Helvetica, source-code-pro, Menlo, Monaco,
    Consolas, "Courier New", monospace;
}

.toolbar-link {
  text-decoration: none;
  color: rgb(69, 69, 69);
}
.link {
  text-decoration: none;
  color: rgb(46, 92, 160);
}
a {
  text-decoration: none;
  color: rgb(46, 92, 160);
}
.locked-icon {
  color: primary;
}

.detailLoader {
  width: 100%;
  position: relative;
  margin-top: -32px;
  height: 3px;
  /* -(margin-top + height) = margin-bottom */
  margin-bottom: 29px;
}

@media only screen and (max-width: 600px) {
  .detailLoader {
    margin-top: -28px;
    /* -(margin-top + height) = margin-bottom */
    margin-bottom: 25px;
  }
}

.detailLoader .loaderBar {
  width: 100%;
  height: 3px;
  position: absolute;
  border-radius: 5px;
  top: 0;
  right: 100%;
  bottom: 0;
  left: 0;
  background-color: rgba(47, 157, 209, 1);
  /* box-shadow: 0 0 10px #7abaff; */
  z-index: 2000;
  width: 0;
  animation: loadingBar 2s linear infinite;
}

@keyframes loadingBar {
  0% {
    left: 0%;
    right: 100%;
    width: 0%;
  }
  10% {
    left: 0%;
    right: 75%;
    width: 25%;
  }
  90% {
    right: 0%;
    left: 75%;
    width: 25%;
  }
  100% {
    left: 100%;
    right: 0%;
    width: 0%;
  }
}

.slowfadein {
  -webkit-animation: fadein 0.75s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.75s; /* Firefox < 16 */
  -ms-animation: fadein 0.75s; /* Internet Explorer */
  -o-animation: fadein 0.75s; /* Opera < 12.1 */
  animation: fadein 0.75s;
}

.mediumfadein {
  -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.5s; /* Firefox < 16 */
  -ms-animation: fadein 0.5s; /* Internet Explorer */
  -o-animation: fadein 0.5s; /* Opera < 12.1 */
  animation: fadein 0.5s;
}

.fastfadein {
  -webkit-animation: fadein 0.3s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.3s; /* Firefox < 16 */
  -ms-animation: fadein 0.3s; /* Internet Explorer */
  -o-animation: fadein 0.3s; /* Opera < 12.1 */
  animation: fadein 0.3s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* hide the count on table */
.pagination__hide-count .MuiTablePagination-caption:nth-of-type(2) {
  display: none;
}

/* extra rule to handle special case for userselect */
.pagination__hide-count.select-user .MuiTablePagination-caption {
  display: none;
}

/* overflow: ellipsis for table cells */
.text-overflow-container {
  position: relative;
  max-width: 100%;
  padding: 0 !important;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  vertical-align: text-bottom !important;
}
.text-overflow-content {
  position: absolute;
  white-space: nowrap;
  overflow-y: visible;
  overflow-x: hidden;
  text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  max-width: 100%;
  min-width: 0;
  width: 100%;
  top: 0;
  left: 0;
}
.text-overflow-container:after,
.text-overflow-content:after {
  content: "-";
  display: inline;
  visibility: hidden;
}
